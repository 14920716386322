import { call, put, select } from 'redux-saga/effects'
import { Action } from 'redux-actions'

import { fetchEditOrder } from 'requests/order'

import { selectToken } from 'store/users/users.selectors'
import { addAlert } from 'store/alerts/alerts.actions'
import { addHttpError } from 'store/http-error/http-error.actions'

import { AlertVariant } from 'types/alert'
import { EditOrderData, OrderFields } from 'types/order'

import { fetchEditOrderFailure, fetchEditOrderSuccess } from '../order.actions'
import { selectOrderRead } from '../order.selectors'

export function* fetchEditOrderSaga(action: Action<EditOrderData>) {
  try {
    const token = yield select(selectToken)
    const form = yield select(selectOrderRead)

    yield call(fetchEditOrder, action.payload, token)

    yield put(
      addAlert({
        text: `Заявка ${form[OrderFields.ORDER_NUMBER]} успешно изменена.`,
        lifetime: 3000,
        variant: AlertVariant.SUCCESS,
        header: 'Изменение заявки',
      }),
    )

    yield put(fetchEditOrderSuccess())
  } catch (error) {
    yield put(fetchEditOrderFailure(error))
    yield put(
      addHttpError({
        error,
        header: 'Редактирование заявки',
        defaultText: 'Не удалось отредактировать заявку.',
      }),
    )
    console.error(`[fetchEditOrderSaga]: ${error}`)
  }
}
