import React, { ReactNode, useCallback, useMemo, useRef } from 'react'
import { Form } from 'react-bootstrap'
import classNames from 'classnames'
import { v4 as uuidv4 } from 'uuid'
import InputMask from 'react-input-mask'

import { convertCyrillicToLatin, getTransportForServer } from 'utils/transport'

export interface InputControlProps {
  id: string
  value: string
  isValid?: boolean | undefined
  onChangeHandler(value: string, id?: string): void
  onPasteHandler?(id?: string, value?: string): void
  className?: string
  label?: string | ReactNode
  invalidMessage?: string
  required?: boolean
  disabled?: boolean
}

export const InputTruck: React.FC<InputControlProps> = ({
  id,
  value,
  isValid,
  onChangeHandler,
  onPasteHandler = () => {},
  className = '',
  label,
  invalidMessage = '',
  required = false,
  disabled = false,
}) => {
  const inputRef: any = useRef(null)

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value.length < 12) {
        const convertedValue = convertCyrillicToLatin(e.target.value)
        onChangeHandler(getTransportForServer(convertedValue), id)
      }
    },
    [onChangeHandler, id],
  )

  const onPaste = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const currValue = e.target.value
      const newValue = currValue.replace(/\s{2,}/g, ' ')
      if (currValue !== newValue) onChangeHandler(newValue, id)

      onPasteHandler(id, newValue)
    },
    [onChangeHandler, id, onPasteHandler],
  )

  // eslint-disable-next-line no-nested-ternary
  const validClass = isValid === false ? 'is-invalid' : isValid === true ? 'is-valid' : ''

  const name = useMemo(() => uuidv4(), [])

  return (
    <Form.Group className="m-0">
      {label ? <Form.Label className="d-block">{label}</Form.Label> : null}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
      <InputMask
        id={id}
        className={classNames('inputMask form-control', validClass, className)}
        value={value || ''}
        mask=""
        maskChar={null}
        onChange={onChange}
        onBlur={onPaste}
        required={required}
        autoComplete="none"
        name={name}
        disabled={disabled}
        ref={inputRef}
        alwaysShowMask={!disabled}
      />
      {invalidMessage && isValid === false ? <Form.Control.Feedback type="invalid">{invalidMessage}</Form.Control.Feedback> : null}
    </Form.Group>
  )
}

export default InputTruck
