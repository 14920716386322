import { CreateCustomerData, EditCustomerData, EditCustomerSettingsData } from 'types/customer'
import { api, transformResponse, v3Api } from './axios'

export const getCustomers = (token: string) =>
  transformResponse(
    api.get('/getCompanies', {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }),
  )

export const createCustomer = (data: CreateCustomerData, token: string) =>
  transformResponse(
    v3Api.post('/customer', data, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }),
  )

export const editCustomer = (data: EditCustomerData, token: string) =>
  transformResponse(
    v3Api.patch(`/customer/${data.companyId}`, data.editFields, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }),
  )

export const editCustomerSettings = (data: EditCustomerSettingsData, token: string) =>
  transformResponse(
    v3Api.patch(`/user/customer/settings`, data, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }),
  )
