import { LoadingStatus } from 'types/loading'

export type DictionaryId = string

export enum DictionaryEntryType {
  PERSON = 'person',
  PHONE = 'phone',
}

export interface DictionaryEntry {
  type: DictionaryEntryType
  [key: string]: any
}

export interface DictionaryLink {
  entryIds: string[]
  createdAt: Date
  data: any
}

export interface GetDictionaryEntryRequest {
  entry: DictionaryEntry
  referenceTypes: DictionaryEntryType[]
}

export interface GetDictionaryEntryResponse {
  entries: DictionaryEntry[]
  links: DictionaryLink[]
}

export type LinkDictionaryEntryRequest = { entries: [DictionaryEntry, DictionaryEntry]; linkData: any }

export type LinkDictionaryEntryResponse = [GetDictionaryEntryResponse, GetDictionaryEntryResponse]

export type UnlinkDictionaryEntryRequest = [DictionaryEntry, DictionaryEntry]

export interface DictionaryEntryState {
  response: GetDictionaryEntryResponse | null
  status: LoadingStatus
}

export interface PersonDictionaryEntry extends DictionaryEntry {
  name: string
  birthday: string
}

export interface PhoneDictionaryEntry extends DictionaryEntry {
  phone: string
}

export enum PhoneDictionaryLinkSource {
  CALL_VERIFIED_PHONE = 'call-verified-phone',
  SBP = 'sbp',
  DG = 'dg',
  BY_DRIVER_PHOTO = 'by-driver-photo',
  UNKNOWN = 'unknown',
}

export const PhoneDictionaryLinkSourceName = {
  [PhoneDictionaryLinkSource.CALL_VERIFIED_PHONE]: 'Звонком на вериф.телефон',
  [PhoneDictionaryLinkSource.SBP]: 'Проверено через СБП',
  [PhoneDictionaryLinkSource.DG]: 'Найдено в DG',
  [PhoneDictionaryLinkSource.BY_DRIVER_PHOTO]: 'По фотографии водителя',
  [PhoneDictionaryLinkSource.UNKNOWN]: 'Неизвестно',
}

export enum L2TaskStatus {
  NEW = 'new',
  IN_PROGRESS = 'in-progress',
  DONE = 'done',
  CANCELED = 'canceled',
}

export const PhoneDictionaryLinkL2StatusName = {
  [L2TaskStatus.NEW]: 'Создана',
  [L2TaskStatus.IN_PROGRESS]: 'В работе',
  [L2TaskStatus.DONE]: 'Выполнена',
  [L2TaskStatus.CANCELED]: 'Отменена',
}
