import { OrderTypeId } from 'types/order'

export const getTitleForCarrierByOrderType = (orderTypeId: OrderTypeId): string => {
  switch (orderTypeId) {
    case OrderTypeId.LOGISTIC_AND_DRIVER:
      return 'Перевозчик, ИП / юрлицо'
    case OrderTypeId.LOGISTIC_PAYER:
      return 'Контрагент, ИП / юрлицо'
    case OrderTypeId.LOGISTIC_CONTRACTOR:
      return 'Контрагент, ИП / юрлицо'
    case OrderTypeId.COUNTERPARTY_BORROWER_AND_TS:
      return 'Контрагент, ИП / юрлицо'
    default:
      return 'Компания, ИП / юрлицо'
  }
}
