import { CreateUserData, DeleteUserData, EditUserData, LoginData, RegisterData, UserSettings } from 'types/user'
import { api, apiFiles, transformResponse, v3Api } from './axios'

export const fetchUserList = (token: string) =>
  transformResponse(
    v3Api.get('/user', {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }),
  )

export const fetchCreateUser = (data: CreateUserData, token: string) =>
  transformResponse(
    api.post('/admin/user', data, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }),
  )

export const fetchEditUser = ({ userId, data }: EditUserData, token: string) =>
  transformResponse(
    v3Api.patch(`/user/${userId}`, data, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }),
  )

export const fetchDeleteUser = (data: DeleteUserData, token: string) =>
  transformResponse(
    api.post('/deleteUser', data, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }),
  )

export const login = (data: LoginData) => transformResponse(v3Api.post('/user/login', data))

export const register = (data: RegisterData) => transformResponse(api.post('/register', data))

export const loginFiles = (token: string) =>
  transformResponse(
    apiFiles.get('/auth', {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }),
  )

export const fetchCurrentUser = (token: string) =>
  transformResponse(
    api.get('/user/current', {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }),
  )

export const updateUserSettings = (data: UserSettings, token: string) =>
  transformResponse(
    api.post('/user/settings', data, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }),
  )

export const deactivateUser = (userLogin: string, token: string) =>
  transformResponse(
    api.delete(`/admin/user/${userLogin}`, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }),
  )
