import React from 'react'
import classNames from 'classnames'
import { useSelector } from 'react-redux'

import { OrderFields, OrderTypeId } from 'types/order'
import { AutoCheckType } from 'types/auto-checks'
import { getTitleForCarrierByOrderType } from 'utils/title'

import { selectOrderRead } from 'store/order/order.selectors'
import { selectAutoChecks } from 'store/autoChecks/autoChecks.selectors'

import TextWithPrefixAndCopy from 'components/molecules/TextWithPrefixAndCopy'
import ButtonGetDataFromDGOnFioAndBirth from 'components/molecules/ButtonGetDataFromDGOnFioAndBirth'
import BacklitTextForCheckedValue from 'components/atoms/BacklitTextForCheckedValue'
import TextWithPrefix from 'components/atoms/TextWithPrefix'
import CertificateLink from 'components/atoms/CertificateLink'
import PhoneForExpert from 'components/molecules/OrderSpace/components/PhoneForExpert'
import { CarrierWarning } from 'components/molecules/OrderSpace/CarrierWarning'
import { Icon, IconType } from 'components/atoms/Icon'
import GrayText from 'components/atoms/GrayText'
import Indent from 'components/atoms/Indent'
import NegativeStatus from 'components/molecules/NegativeStatus'
import BoldText from 'components/atoms/BoldText'

import getStringFromTwoFields from 'utils/getStringFromTwoFields'

import classes from './style.module.css'
import { getCheckByType } from '../Driver/utils'

const Expert: React.FC = () => {
  const form = useSelector(selectOrderRead)

  const autoCheck = useSelector(selectAutoChecks)?.filter((check) => check.type === AutoCheckType.LOGISTIC_FIRST_FACE)

  const checkTel1 = getCheckByType(autoCheck, 'tel1')
  const checkTel2 = getCheckByType(autoCheck, 'tel2')
  const negative = getCheckByType(autoCheck, 'negative')

  const checkChapters =
    autoCheck && autoCheck.length && autoCheck[0].checks.filter((check: any) => check.type === 'chapters').length
      ? autoCheck[0].checks.filter((check: any) => check.type === 'chapters')[0]
      : null

  const orderTypeId = String(form[OrderFields.ORDER_TYPE])
  const titleText = getTitleForCarrierByOrderType(orderTypeId as OrderTypeId)

  return (
    <>
      <ul className="list-group row needs-validation">
        <li className="list-group-item">
          <div className="p-0 position-relative">
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <span
              onClick={() => {
                window.scrollTo(0, 0)
              }}
              className={classNames(classes.anchor)}
            >
              <Icon type={IconType.BusinessCenter2} width="24px" height="24px" />
            </span>
            <div className={classes.title}>{titleText}</div>
            <p className="p-0 m-0">
              <BoldText text={form[OrderFields.LOGISTIC_NAME]} />
            </p>
            {form[OrderFields.LOGISTIC_INN]?.length === 9 ? (
              <TextWithPrefixAndCopy
                text={
                  <BoldText
                    text={
                      <a target="_blank" rel="noopener noreferrer" href={`https://kartoteka.by/unp-${form[OrderFields.LOGISTIC_INN]}`}>
                        {form[OrderFields.LOGISTIC_INN]}
                      </a>
                    }
                  />
                }
                prefix="ИНН:"
                copiedText={form[OrderFields.LOGISTIC_INN]}
              />
            ) : (
              <TextWithPrefixAndCopy
                text={<BoldText text={form[OrderFields.LOGISTIC_INN]} />}
                prefix="ИНН:"
                copiedText={form[OrderFields.LOGISTIC_INN]}
              />
            )}
            <Indent>
              <CertificateLink type="company" identityData={{ ИНН: form[OrderFields.LOGISTIC_INN] }} />
              <CarrierWarning />
            </Indent>

            <TextWithPrefixAndCopy
              text={
                <BoldText
                  text={
                    <a target="_blank" rel="noopener noreferrer" href={`https://reputation.ru/${form[OrderFields.LOGISTIC_OGRN]}`}>
                      {form[OrderFields.LOGISTIC_OGRN]}
                    </a>
                  }
                />
              }
              prefix="ОГРН(ИП):"
              copiedText={form[OrderFields.LOGISTIC_OGRN]}
            />
            <Indent>
              <BacklitTextForCheckedValue field={OrderFields.LOGISTIC_OGRN} />
            </Indent>

            {/* В данной проверке есть смысл! Дело в том, что сервер возвращает false/true/null. По условию подходит только false! */}
            {form[OrderFields.LOGISTIC_ATI_STATUS] === false ? (
              <TextWithPrefix text="Нет в АТИ" prefix="АТИ:" />
            ) : (
              <TextWithPrefixAndCopy
                text={
                  <a target="_blank" rel="noopener noreferrer" href={`https://ati.su/firms/${form[OrderFields.LOGISTIC_ATI]}/info`}>
                    {form[OrderFields.LOGISTIC_ATI]}
                  </a>
                }
                prefix="АТИ:"
                copiedText={form[OrderFields.LOGISTIC_ATI]}
              />
            )}
            <br />
            <TextWithPrefixAndCopy
              text={<BoldText text={getStringFromTwoFields(form[OrderFields.LOGISTIC_FIRST_FACE], form[OrderFields.LOGISTIC_FIRST_FACE_BITH])} />}
              prefix="ФИО:"
              copiedText={getStringFromTwoFields(form[OrderFields.LOGISTIC_FIRST_FACE], form[OrderFields.LOGISTIC_FIRST_FACE_BITH])}
            />
            <Indent>
              <BacklitTextForCheckedValue field={OrderFields.LOGISTIC_FIRST_FACE} />
              <ButtonGetDataFromDGOnFioAndBirth
                fio={form[OrderFields.LOGISTIC_FIRST_FACE]}
                date={form[OrderFields.LOGISTIC_FIRST_FACE_BITH]}
                bases={checkChapters ? checkChapters.data : null}
              />
              <NegativeStatus check={negative} />
            </Indent>
            <TextWithPrefixAndCopy
              text={<BoldText text={form[OrderFields.LOGISTIC_FIRST_FACE_INN]} />}
              copiedText={form[OrderFields.LOGISTIC_FIRST_FACE_INN]}
              prefix="ИНН 1го лица:"
            />
            <br />

            <GrayText text="Телефоны в заявке:" />

            <div className="mb-2" style={{ marginTop: '2px' }}>
              <PhoneForExpert check={checkTel1} field={OrderFields.CARRIER_FIRST_FACE_PHONE_1} />
            </div>
            <div className="mb-2">
              <PhoneForExpert check={checkTel2} field={OrderFields.CARRIER_FIRST_FACE_PHONE_2} />
            </div>
          </div>
        </li>
      </ul>
    </>
  )
}

export default Expert
