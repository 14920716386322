import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useParams } from 'react-router'

import { ordersListPath } from 'routes/url-constants'

import { OrderFields, OrderTypeId, ResolutionId } from 'types/order'
import { LoadingStatus } from 'types/loading'
import { DictionaryEntryType } from 'types/dictionary'

import { selectLoadingResolutionFormStatus, selectOrderRead } from 'store/order/order.selectors'
import { selectSelectLists } from 'store/selectLists/selectLists.selectors'
import { fetchSetResolutionRequest } from 'store/order/order.actions'
import { selectCurrentUser } from 'store/users/users.selectors'
import { usePhoneDictionaryLinksByEntry } from 'store/dictionary/dictionary.hooks'

import SelectControl from 'components/molecules/SelectControl'
import TextareaControl from 'components/molecules/TextareaControl'
import Button from 'components/molecules/Button'
import SwitchControl from 'components/molecules/SwitchControl'
import { Icon, IconType } from 'components/atoms/Icon'

import { ORDER_TYPE_FOR_FAST_SUBMIT } from './constants'

import { getPhrasesForResolutionComment, getResolutions } from './utils'
import { Resolution } from './resolution/Resolution'

import { DriverPhoneVerification } from './resolution/DriverPhoneVerification'

import classes from './style.module.css'

const ResolutionForm: React.FC = () => {
  const dispatch = useDispatch()
  const { orderNumber } = useParams()

  const orderFields = useSelector(selectOrderRead)
  const selectLists = useSelector(selectSelectLists)
  const currentUser = useSelector(selectCurrentUser)
  const loadingStatus = useSelector(selectLoadingResolutionFormStatus)

  const [verifiedPhones, setVerifiedPhones] = useState<string[]>([])

  const [dictionaryLinkOfFirstPhone, dictionaryLinkOfSecondPhone] = usePhoneDictionaryLinksByEntry(
    {
      name: orderFields[OrderFields.DRIVER_FIO],
      birthday: orderFields[OrderFields.DRIVER_BITH],
      type: DictionaryEntryType.PERSON,
    },
    [orderFields[OrderFields.DRIVER_TEL_1], orderFields[OrderFields.DRIVER_TEL_2]],
  )

  useEffect(() => {
    const firstPhoneIsVerified = !!dictionaryLinkOfFirstPhone?.data?.linkSource
    const secondPhoneIsVerified = !!dictionaryLinkOfSecondPhone?.data?.linkSource

    const phones = []
    if (firstPhoneIsVerified) phones.push(orderFields[OrderFields.DRIVER_TEL_1])
    if (secondPhoneIsVerified) phones.push(orderFields[OrderFields.DRIVER_TEL_2])
    setVerifiedPhones(phones)
  }, [dictionaryLinkOfFirstPhone, dictionaryLinkOfSecondPhone, orderFields])

  const [form, setForm] = useState({
    [OrderFields.RESOLUTION]: orderFields[OrderFields.RESOLUTION],
    [OrderFields.RESOLUTION_COMMENT]: orderFields[OrderFields.RESOLUTION_COMMENT],
    [OrderFields.ORDER_COMMENT]: orderFields[OrderFields.ORDER_COMMENT],
    [OrderFields.HAS_BELARUSIANS]: orderFields[OrderFields.HAS_BELARUSIANS],
  })

  const onChange = (value: string | boolean | null, id: string) => {
    setForm((prev) => ({
      ...prev,
      [id]: value,
    }))
  }

  const onSubmit = useCallback(
    (fields?: { [OrderFields.RESOLUTION]: string; [OrderFields.RESOLUTION_COMMENT]: string }) => {
      dispatch(
        fetchSetResolutionRequest({
          [OrderFields.ORDER_NUMBER]: orderNumber,
          fields: {
            ...(fields || form),
          },
        }),
      )
    },
    [dispatch, form, orderNumber],
  )

  const onFastSubmit = useCallback(() => {
    const resolution = ORDER_TYPE_FOR_FAST_SUBMIT.includes(String(orderFields[OrderFields.ORDER_TYPE]) as OrderTypeId)
      ? ResolutionId.COOPERATION_AGREED
      : ResolutionId.SHIPMENT_ALLOWED
    onChange(resolution, OrderFields.RESOLUTION)
    onSubmit({ ...form, [OrderFields.RESOLUTION]: resolution })
  }, [form, onSubmit, orderFields])

  const driverPhoneVerification = useMemo(
    () => new DriverPhoneVerification(verifiedPhones, [orderFields[OrderFields.DRIVER_TEL_1], orderFields[OrderFields.DRIVER_TEL_2]]),
    [orderFields, verifiedPhones],
  )

  const resolution = useMemo(
    () =>
      new Resolution(
        currentUser!.id!,
        orderFields[OrderFields.EXECUTOR_EXPERT],
        orderFields[OrderFields.RESOLUTION] as ResolutionId,
        orderFields[OrderFields.ORDER_TYPE] as OrderTypeId,
        form[OrderFields.RESOLUTION] as ResolutionId,
        orderFields[OrderFields.DRIVER_TEL_1],
        orderFields[OrderFields.DRIVER_TEL_2],
      ),
    [currentUser, form, orderFields],
  )

  const phrasesForResolutionComment = useMemo(() => getPhrasesForResolutionComment(orderFields[OrderFields.ORDER_TYPE]), [orderFields])

  const resolutions = useMemo(() => getResolutions(orderFields, selectLists[OrderFields.RESOLUTION]), [selectLists, orderFields])

  const userIsExecutor = resolution.isUserExecutor()
  const resolutionIsPositive = resolution.isResolutionPositive()
  const orderHasDriver = resolution.hasOrderDriver()

  const driverHasPhone = resolution.hasDriverPhone()
  const driverHasBothPhones = resolution.hasDriverBothPhones()

  const positiveResolutionButtonIsDisabled = resolution.canNotSetPositiveResolution(
    driverPhoneVerification.isVerifiedPhone(orderFields[OrderFields.DRIVER_TEL_1]),
    driverPhoneVerification.isVerifiedPhone(orderFields[OrderFields.DRIVER_TEL_2]),
  )
  const finalResolutionButtonIsDisabled = resolution.canNotSetFinalResolution(
    driverPhoneVerification.isVerifiedPhone(orderFields[OrderFields.DRIVER_TEL_1]),
    driverPhoneVerification.isVerifiedPhone(orderFields[OrderFields.DRIVER_TEL_2]),
  )

  const verificationValidationMessageIsVisible = driverPhoneVerification.isVerificationValidationMessageVisible(
    orderHasDriver,
    resolutionIsPositive,
    driverHasPhone,
    driverHasBothPhones,
  )

  if (loadingStatus === LoadingStatus.SUCCESS) return <Redirect to={ordersListPath} />

  return (
    <>
      <div style={{ marginBottom: '12px' }}>
        <Button
          className="btn btn-primary col-lg-3 col-md-6 col-sm-12"
          onClick={() => onFastSubmit()}
          disabled={positiveResolutionButtonIsDisabled}
          variant="success"
          isLoading={loadingStatus === LoadingStatus.PENDING}
          content={
            <div className="d-flex align-items-center justify-content-center">
              <Icon type={IconType.Check} width="18px" height="18px" className={classes.iconCheck} />
              <span style={{ marginLeft: '4px' }}>
                {ORDER_TYPE_FOR_FAST_SUBMIT.includes(String(orderFields[OrderFields.ORDER_TYPE]) as OrderTypeId)
                  ? 'Сотрудничество согласовано'
                  : 'Разрешить отгрузку'}
              </span>
            </div>
          }
        />
      </div>
      <div className={classes.orderComment}>
        <TextareaControl
          label="Внутренний комментарий"
          value={form[OrderFields.ORDER_COMMENT]}
          id={OrderFields.ORDER_COMMENT}
          onChangeHandler={onChange}
          placeholder="Внутренний комментарий"
          withCross
          disabled={!userIsExecutor}
        />
      </div>
      <div className={classes.resolutionComment}>
        <TextareaControl
          label="Комментарий клиенту"
          value={form[OrderFields.RESOLUTION_COMMENT]}
          id={OrderFields.RESOLUTION_COMMENT}
          onChangeHandler={onChange}
          placeholder="Комментарий клиенту"
          withCross
          disabled={!userIsExecutor}
          phrases={phrasesForResolutionComment}
        />
      </div>
      {!orderFields[OrderFields.EXECUTOR_L1] ? (
        <div style={{ marginBottom: '8px' }}>
          <SwitchControl
            id={OrderFields.HAS_BELARUSIANS}
            value={form[OrderFields.HAS_BELARUSIANS]}
            onChangeHandler={onChange}
            label="В заявке есть белорусы"
          />
        </div>
      ) : null}
      <div style={{ marginBottom: '8px' }}>
        <SelectControl
          value={form[OrderFields.RESOLUTION]}
          options={resolutions}
          id={OrderFields.RESOLUTION}
          onChangeHandler={onChange}
          className="col-lg-4 col-md-6 col-sm-12"
          disabled={!userIsExecutor}
          invalidMessage={!userIsExecutor ? 'Вы не являетесь исполнителем данной заявки' : ''}
          isValid={userIsExecutor ? undefined : false}
        />
      </div>
      <Button
        onClick={() => onSubmit()}
        className="col-6 col-lg-3"
        disabled={finalResolutionButtonIsDisabled}
        content="Завершить заявку"
        isLoading={loadingStatus === LoadingStatus.PENDING}
      />
      {verificationValidationMessageIsVisible ? (
        <p className="p-0 small" style={{ color: '#dc3545', margin: '4px 0 0 0' }}>
          Верифицируйте все телефоны водителя выше
        </p>
      ) : null}
    </>
  )
}

export default ResolutionForm
