import { call, put, select } from 'redux-saga/effects'
import { Action } from 'redux-actions'

import { CloseOrder } from 'requests/order'
import { selectToken } from 'store/users/users.selectors'
import { addAlert } from 'store/alerts/alerts.actions'
import { addHttpError } from 'store/http-error/http-error.actions'

import { AlertVariant } from 'types/alert'
import { EditOrderData } from 'types/order'

import { fetchSetResolutionSuccess, fetchSetResolutionFailure } from '../order.actions'

export function* fetchSetResolutionSaga(action: Action<EditOrderData>) {
  try {
    const token = yield select(selectToken)

    yield call(CloseOrder, action.payload, token)
    yield put(fetchSetResolutionSuccess())
    yield put(
      addAlert({
        text: `Изменение резолюции прошло успешно.`,
        lifetime: 3000,
        variant: AlertVariant.SUCCESS,
        header: 'Заявка',
      }),
    )
  } catch (error) {
    yield put(fetchSetResolutionFailure(error))
    yield put(
      addHttpError({
        error,
        header: 'Изменение резолюции',
        defaultText: 'Не удалось изменить резолюцию.',
      }),
    )
    console.error(`[fetchSetResolutionSaga]: ${error}`)
  }
}
