import { Order, OrderFields, ResolutionId } from 'types/order'
import { groupOrders } from './group-orders'
import { SortableOrder } from '../sortable-order'

export class L1Sorter {
  static sort(orders: Order[]) {
    const descSortedOrders = orders.reverse()
    const [newOrders, notNewOrders] = groupOrders(descSortedOrders, L1Sorter.isNew)
    const [l1ProcessingOrders, notProcessingOrders] = groupOrders(notNewOrders, L1Sorter.isInL1Processing)
    const [onClarificationOrders, notOnClarificationOrders] = groupOrders(notProcessingOrders, L1Sorter.isOnClarification)
    const [onDriverNotRespondingOrders, otherOrders] = groupOrders(notOnClarificationOrders, L1Sorter.isOnDriverNotResponding)
    return [...l1ProcessingOrders, ...newOrders, ...onClarificationOrders, ...onDriverNotRespondingOrders, ...otherOrders]
  }

  static isNew(order: SortableOrder) {
    return (String(order[OrderFields.RESOLUTION]) as ResolutionId) === ResolutionId.NEW_ORDER
  }

  static isInL1Processing(order: SortableOrder) {
    return (String(order[OrderFields.RESOLUTION]) as ResolutionId) === ResolutionId.L1_PROCESSING
  }

  static isOnClarification(order: SortableOrder) {
    return (String(order[OrderFields.RESOLUTION]) as ResolutionId) === ResolutionId.CLARIFY_DATA
  }

  static isOnDriverNotResponding(order: SortableOrder) {
    return (String(order[OrderFields.RESOLUTION]) as ResolutionId) === ResolutionId.DRIVER_IS_NOT_RESPONDING
  }
}
