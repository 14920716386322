import React, { useMemo } from 'react'

import { AutoCheck } from 'types/auto-checks'
import { Feature } from 'types/user'

import GrayText from 'components/atoms/GrayText'

interface CriminalCodesProps {
  criminal: AutoCheck
  customerFeatures: { [key in Feature]?: any }
}

export const CriminalCodes: React.FC<CriminalCodesProps> = ({ criminal, customerFeatures }) => {
  const riskyCriminalCodesAfter1997 = useMemo(() => {
    return (customerFeatures?.riskyCriminalCodesAfter1997 || []) as Array<number>
  }, [customerFeatures])

  const riskyCriminalCodesBefore1997 = useMemo(() => {
    return (customerFeatures?.riskyCriminalCodesBefore1997 || []) as Array<number>
  }, [customerFeatures])

  const criminalCodes = useMemo(
    () =>
      (criminal?.data?.criminalCodes || []).map((code: any) => ({
        ...code,
        wasFound: Number(code.year) >= 1997 ? riskyCriminalCodesAfter1997.includes(code.number) : riskyCriminalCodesBefore1997.includes(code.number),
      })),
    [criminal, riskyCriminalCodesAfter1997, riskyCriminalCodesBefore1997],
  )

  return (
    <>
      {criminalCodes.length ? (
        <>
          <GrayText text="Найденные статьи:" />
          <div>
            {criminalCodes.map((article: any, index: number) => (
              <>
                <span
                  key={article.number}
                  className={article.wasFound ? 'font-weight-bold' : ''}
                >{`${article.number} (${article.year} - ${article.country})`}</span>
                {index + 1 < criminalCodes.length ? ', ' : null}
              </>
            ))}
          </div>
        </>
      ) : null}
      {riskyCriminalCodesAfter1997.length ? (
        <>
          <GrayText text="Критичные статьи клиента c 1997г.:" />
          <div>{riskyCriminalCodesAfter1997.map((article) => article).join(', ')}</div>
        </>
      ) : null}
      {riskyCriminalCodesBefore1997.length ? (
        <>
          <GrayText text="Критичные статьи клиента до 1997г.:" />
          <div>{riskyCriminalCodesBefore1997.map((article) => article).join(', ')}</div>
        </>
      ) : null}
    </>
  )
}

export default CriminalCodes
