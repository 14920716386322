import React from 'react'
import { useSelector } from 'react-redux'

import { OrderFields } from 'types/order'

import { selectOrderFormErrors, selectOrderWrite } from 'store/order/order.selectors'

import Title from 'components/atoms/Title'
import InputPhone from 'components/molecules/InputPhone'
import InputControl from 'components/molecules/InputControl'
import InputFio from 'components/molecules/InputFio'
import { LogisticProps } from '../Logistic'

const Customer: React.FC<LogisticProps> = ({ onChange }) => {
  const form = useSelector(selectOrderWrite)
  const errors = useSelector(selectOrderFormErrors)

  const contactFullNameIsValid = errors[OrderFields.LOGISTIC_FIO].isValid
  const contactPhoneIsValid = errors[OrderFields.LOGISTIC_TEL].isValid
  const contactEmailIsValid = errors[OrderFields.CARRIER_EMAIL].isValid

  return (
    <>
      <Title text="Контакты" />
      {!contactFullNameIsValid || !contactPhoneIsValid || !contactEmailIsValid ? (
        <div style={{ margin: '4px -16px' }}>
          <div role="alert" className="fade m-0 alert alert-warning show">
            Внимание!
            <br />
            Заполните контакты, через которые получены документы от компании, и появится следующая часть формы.
          </div>
        </div>
      ) : null}
      <ul className="list-group needs-validation">
        <li className="list-group-item row">
          <div className="p-0">
            <InputFio
              value={form[OrderFields.LOGISTIC_FIO]}
              isValid={errors[OrderFields.LOGISTIC_FIO].isValid}
              invalidMessage={errors[OrderFields.LOGISTIC_FIO].invalidMessage}
              id={OrderFields.LOGISTIC_FIO}
              onChangeHandler={onChange}
              className="col-lg-4 col-md-6 col-sm-12"
              label="ФИО контакта:"
            />
          </div>
        </li>
        <li className="list-group-item row">
          <div className="p-0">
            <InputPhone
              value={form[OrderFields.LOGISTIC_TEL]}
              isValid={errors[OrderFields.LOGISTIC_TEL].isValid}
              invalidMessage={errors[OrderFields.LOGISTIC_TEL].invalidMessage}
              id={OrderFields.LOGISTIC_TEL}
              onChangeHandler={onChange}
              className="col-lg-4 col-md-6 col-sm-12"
              label="Телефон, по которому ведется общение:"
            />
          </div>
        </li>
        <li className="list-group-item row">
          <div className="p-0">
            <InputPhone
              value={form[OrderFields.LOGISTIC_TEL_2]}
              isValid={errors[OrderFields.LOGISTIC_TEL_2].isValid}
              invalidMessage={errors[OrderFields.LOGISTIC_TEL_2].invalidMessage}
              id={OrderFields.LOGISTIC_TEL_2}
              onChangeHandler={onChange}
              className="col-lg-4 col-md-6 col-sm-12"
              label="Второй телефон, по которому ведется общение:"
            />
          </div>
        </li>
        <li className="list-group-item row">
          <div className="p-0">
            <InputControl
              type="email"
              value={form[OrderFields.CARRIER_EMAIL]}
              isValid={errors[OrderFields.CARRIER_EMAIL].isValid}
              invalidMessage={errors[OrderFields.CARRIER_EMAIL].invalidMessage}
              id={OrderFields.CARRIER_EMAIL}
              onChangeHandler={onChange}
              className="col-lg-4 col-md-6 col-sm-12"
              label="Email, с которого прислали документы:"
            />
          </div>
        </li>
      </ul>
    </>
  )
}

export default Customer
