import React from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import { LoadingStatus } from 'types/loading'
import { OrderFields } from 'types/order'
import { AutoCheck, AutoCheckStatus } from 'types/auto-checks'
import { coincidenceFromDGOnFioAndBirth } from 'types/data-gateway'
import {
  DictionaryLink,
  L2TaskStatus,
  PhoneDictionaryLinkL2StatusName,
  PhoneDictionaryLinkSource,
  PhoneDictionaryLinkSourceName,
} from 'types/dictionary'
import { selectOrderRead } from 'store/order/order.selectors'
import { selectLoadingAutoCheck } from 'store/autoChecks/autoChecks.selectors'
import TextWithPrefixAndCopy from 'components/molecules/TextWithPrefixAndCopy'
import ButtonGetDataFromDGOnTel from 'components/molecules/ButtonGetDataFromDGOnTel'
import { ViewerImage, ViewerImageButton } from 'components/molecules/ViewerImage'
import WhatsappNumberLink from 'components/atoms/WhatsappNumberLink'
import PhoneNumberLink from 'components/atoms/PhoneNumberLink'
import CertificateLink from 'components/atoms/CertificateLink'
import ViberNumberLink from 'components/atoms/ViberNumberLink'
import PhoneRegion from 'components/atoms/PhoneRegion'
import BoldText from 'components/atoms/BoldText'
import Indent from 'components/atoms/Indent'
import TelegramNumberLink from 'components/atoms/TelegramNumberLink'
import classes from './style.module.css'

interface PhoneForExpertProps {
  field:
    | OrderFields.LOGISTIC_TEL
    | OrderFields.LOGISTIC_TEL_2
    | OrderFields.CARRIER_FIRST_FACE_PHONE_1
    | OrderFields.CARRIER_FIRST_FACE_PHONE_2
    | OrderFields.DRIVER_TEL_1
    | OrderFields.DRIVER_TEL_2
  check: AutoCheck
  dictionaryLink?: DictionaryLink
  children?: React.ReactNode
}

export const PhoneForExpert: React.FC<PhoneForExpertProps> = ({ field, check, dictionaryLink, children }) => {
  const form = useSelector(selectOrderRead)
  const loadingAutoCheckStatus = useSelector(selectLoadingAutoCheck)
  const isLoadingChecks = loadingAutoCheckStatus === LoadingStatus.PENDING
  const hasCoincidences = check?.data?.coincidences?.length
  const isPositiveCheck = [AutoCheckStatus.SUCCESS, AutoCheckStatus.INDETERMINATE].includes(check?.status)
  const [isOpenDriverPhoto, setIsOpenDriverPhoto] = React.useState(false)

  if (!form[field]) return null

  return (
    <>
      <div className="d-flex flex-column" style={{ gap: '2px' }}>
        <div className="d-flex justify-content-between">
          <div className="d-inline-block">
            <TextWithPrefixAndCopy text={<BoldText text={<PhoneNumberLink phone={form[field]} />} />} prefix="Тел:" copiedText={form[field]} />
          </div>
          <div className="d-inline-block flex-grow-1">{children}</div>
        </div>
        <Indent>
          <div className="d-flex align-items-center align-middle">
            <WhatsappNumberLink phone={form[field]} />
            <TelegramNumberLink phone={form[field]} />
            <ViberNumberLink phone={form[field]} />
          </div>
          <PhoneRegion phone={form[field]} />
          {dictionaryLink?.data?.linkSource ? (
            <div style={{ color: 'rgb(153, 153, 153)' }}>
              Способ верификации: {PhoneDictionaryLinkSourceName[dictionaryLink?.data?.linkSource as PhoneDictionaryLinkSource]}
            </div>
          ) : null}
          {dictionaryLink?.data?.l2?.status ? (
            <div style={{ color: 'rgb(153, 153, 153)' }}>
              Статус задачи L2: {PhoneDictionaryLinkL2StatusName[dictionaryLink?.data?.l2?.status as L2TaskStatus]}
            </div>
          ) : null}
          {dictionaryLink?.data?.l2?.comment ? (
            <div style={{ color: 'rgb(153, 153, 153)' }}>Комментарий от L2: {dictionaryLink?.data?.l2?.comment}</div>
          ) : null}
          {dictionaryLink?.data?.l2?.driverPhoto ? (
            <div style={{ color: 'rgb(153, 153, 153)' }}>
              <button type="button" className={classes.button} onClick={() => setIsOpenDriverPhoto(true)}>
                Посмотреть фотографию водителя
              </button>
              {isOpenDriverPhoto ? (
                <ViewerImage
                  image={{ name: 'driverPhoto', href_file: dictionaryLink?.data?.l2?.driverPhoto, data: {} }}
                  onClose={() => setIsOpenDriverPhoto(false)}
                  buttons={[ViewerImageButton.CLOSE]}
                />
              ) : null}
            </div>
          ) : null}
          <ButtonGetDataFromDGOnTel tel={form[field]} check={check} />
          <CertificateLink type="phone" identityData={{ Телефон: form[field] }} />
        </Indent>
      </div>
      {!isLoadingChecks && hasCoincidences && !isPositiveCheck ? (
        <div className={classNames('text-secondary', 'small', classes.coincidencesList)} style={{ marginBottom: '8px', color: 'rgb(153, 153, 153)' }}>
          {check.data.coincidences.map((card: coincidenceFromDGOnFioAndBirth) => (
            <div key={`${card.lastName} ${card.firstName} ${card.middleName}`}>
              {`${card.lastName || ''} ${card.firstName || ''} ${card.middleName || ''}`}
            </div>
          ))}
        </div>
      ) : null}
    </>
  )
}

export default PhoneForExpert
