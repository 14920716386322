import React, { useCallback, useMemo } from 'react'
import classNames from 'classnames'
import { Spinner } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { DictionaryEntry, DictionaryEntryType, DictionaryLink, PhoneDictionaryLinkSource, PhoneDictionaryLinkSourceName } from 'types/dictionary'
import { LoadingStatus } from 'types/loading'
import { linkDictionaryEntriesRequest } from 'store/dictionary/dictionary.actions'
import { useDictionaryByEntry } from 'store/dictionary/dictionary.hooks'
import { Icon, IconType } from 'components/atoms/Icon'
import DropdownMenu, { DropdownMenuItemProps } from 'components/molecules/DropdownMenu'
import classes from './style.module.css'

interface PhoneVerificationPanelProps {
  person: {
    name: string
    birthday: string
  }
  phone: string
  dictionaryLink?: DictionaryLink
}

export const PhoneVerificationPanel: React.FC<PhoneVerificationPanelProps> = ({ person, phone, dictionaryLink }) => {
  const dispatch = useDispatch()

  const personEntry = useDictionaryByEntry({ ...person, type: DictionaryEntryType.PERSON })
  const phoneEntry = useDictionaryByEntry({ phone, type: DictionaryEntryType.PHONE })
  const isLoading = (phoneEntry?.status || personEntry?.status) === LoadingStatus.PENDING

  const isVerified = useMemo(() => !!dictionaryLink?.data?.linkSource, [dictionaryLink])

  const iconType = useMemo(() => {
    if (isVerified) return IconType.CheckCircle
    return IconType.Hint
  }, [isVerified])

  const iconColorClass = useMemo(() => {
    if (isVerified) return 'verified'
    return 'unverified'
  }, [isVerified])

  const verifyPhone = useCallback(
    (item: DropdownMenuItemProps) => {
      const entries: [DictionaryEntry, DictionaryEntry] = [
        { ...person, type: DictionaryEntryType.PERSON },
        { phone, type: DictionaryEntryType.PHONE },
      ]
      const linkData = { linkSource: item.id }

      dispatch(linkDictionaryEntriesRequest({ entries, linkData }))
    },
    [dispatch, person, phone],
  )

  const dropdownList: DropdownMenuItemProps[] = useMemo(() => {
    if (isVerified) {
      return [
        {
          id: null,
          node: 'Убрать верификацию',
        },
      ]
    }
    return Object.values(PhoneDictionaryLinkSource)
      .filter((source) => source !== PhoneDictionaryLinkSource.UNKNOWN)
      .map((source) => ({
        id: source,
        node: PhoneDictionaryLinkSourceName[source],
      }))
  }, [isVerified])

  return (
    <div className="ml-2">
      {isLoading ? (
        <Spinner as="span" animation="grow" style={{ width: '22px', height: '22px' }} role="status" aria-hidden="true" />
      ) : (
        <div className="d-flex" style={{ gap: '8px' }}>
          <div className={classNames(classes.status, classes[iconColorClass])}>
            <Icon type={iconType} width="24px" height="24px" />
          </div>
          <div className={classes.button}>
            <DropdownMenu
              toggleText={<Icon type={IconType.MenuDotted} width="24px" height="24px" />}
              toggleVariant="link"
              classNameForMenuItem={classes.dropdownItem}
              onClickItem={verifyPhone}
              list={dropdownList}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default PhoneVerificationPanel
