import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  DictionaryEntryType,
  L2TaskStatus,
  PhoneDictionaryLinkL2StatusName,
  PhoneDictionaryLinkSource,
  PhoneDictionaryLinkSourceName,
} from 'types/dictionary'
import { linkDictionaryEntriesRequest } from 'store/dictionary/dictionary.actions'
import { useDictionaryByEntry, usePhoneDictionaryLinksByEntry } from 'store/dictionary/dictionary.hooks'
import TextWithPrefixAndCopy from 'components/molecules/TextWithPrefixAndCopy'
import BoldText from 'components/atoms/BoldText'
import PhoneNumberLink from 'components/atoms/PhoneNumberLink'
import PhoneVerificationPanel from 'components/molecules/OrderSpace/components/PhoneVerificationPanel'
import DictionaryPhoneForm from 'components/molecules/OrderSpace/components/DictionaryPhoneForm'
import WhatsappNumberLink from 'components/atoms/WhatsappNumberLink'
import ViberNumberLink from 'components/atoms/ViberNumberLink'
import Indent from 'components/atoms/Indent'
import classes from 'components/molecules/OrderSpace/components/PhoneForExpert/style.module.css'
import { ViewerImage, ViewerImageButton } from 'components/molecules/ViewerImage'
import TelegramNumberLink from 'components/atoms/TelegramNumberLink'

interface PhonesFromDictionaryProps {
  person: {
    name: string
    birthday: string
  }
  existingPhones: string[]
}

export const PhonesFromDictionary: React.FC<PhonesFromDictionaryProps> = ({ person, existingPhones }) => {
  const dispatch = useDispatch()
  const [phoneFormValue, setPhoneFormValue] = useState('')
  const [phones, setPhones] = useState<string[]>([])
  const personEntry = useDictionaryByEntry({ ...person, type: DictionaryEntryType.PERSON })
  const phoneFormEntry = useDictionaryByEntry({ phone: phoneFormValue, type: DictionaryEntryType.PHONE })
  const dictionaryLinks = usePhoneDictionaryLinksByEntry({ ...person, type: DictionaryEntryType.PERSON }, phones)

  const [selectedDriverPhoto, setSelectedDriverPhoto] = useState<string | null>(null)

  useEffect(() => {
    setPhones((prev) => {
      const newState = [...prev]
      const entries = personEntry?.response?.entries || []
      entries.forEach((entry) => {
        const isPhone = entry.type === DictionaryEntryType.PHONE
        const isNewPhone = !prev.find((phone) => phone === entry.phone)
        const inNotExistingPhone = !existingPhones.includes(entry.phone)
        if (isPhone && isNewPhone && inNotExistingPhone) newState.push(entry.phone)
      })
      return newState
    })
  }, [existingPhones, personEntry])

  const handleAddPhone = async (phone: string, linkSource: PhoneDictionaryLinkSource) => {
    const firstEntry = { ...person, type: DictionaryEntryType.PERSON }
    const secondEntry = { phone, type: DictionaryEntryType.PHONE }
    dispatch(linkDictionaryEntriesRequest({ entries: [firstEntry, secondEntry], linkData: { linkSource } }))
  }

  return (
    <>
      <div className="d-flex flex-column" style={{ gap: '4px' }}>
        {phones.map((phone, index) => (
          <div key={phone}>
            <div className="d-flex justify-content-between">
              <div className="d-inline-block">
                <TextWithPrefixAndCopy text={<BoldText text={<PhoneNumberLink phone={phone} />} />} prefix="Тел:" copiedText={phone} />
              </div>
              <div className="d-inline-block flex-grow-1">
                <PhoneVerificationPanel person={person} phone={phone} dictionaryLink={dictionaryLinks[index]} />
              </div>
            </div>
            <Indent>
              <div className="d-flex align-items-center align-middle" style={{ marginBottom: '4px' }}>
                <WhatsappNumberLink phone={phone} />
                <TelegramNumberLink phone={phone} />
                <ViberNumberLink phone={phone} />
              </div>
              {dictionaryLinks[index]?.data?.linkSource ? (
                <div style={{ color: 'rgb(153, 153, 153)' }}>
                  Способ верификации: {PhoneDictionaryLinkSourceName[dictionaryLinks[index]?.data?.linkSource as PhoneDictionaryLinkSource]}
                </div>
              ) : null}
              {dictionaryLinks[index]?.data?.l2?.status ? (
                <div style={{ color: 'rgb(153, 153, 153)' }}>
                  Статус задачи L2: {PhoneDictionaryLinkL2StatusName[dictionaryLinks[index]?.data?.l2?.status as L2TaskStatus]}
                </div>
              ) : null}
              {dictionaryLinks[index]?.data?.l2?.comment ? (
                <div style={{ color: 'rgb(153, 153, 153)' }}>Комментарий от L2: {dictionaryLinks[index]?.data?.l2?.comment}</div>
              ) : null}
              {dictionaryLinks[index]?.data?.l2?.driverPhoto ? (
                <div style={{ color: 'rgb(153, 153, 153)' }}>
                  <button
                    type="button"
                    className={classes.button}
                    onClick={() => setSelectedDriverPhoto(dictionaryLinks[index]?.data?.l2?.driverPhoto)}
                  >
                    Посмотреть фотографию водителя
                  </button>
                  {selectedDriverPhoto === dictionaryLinks[index]?.data?.l2?.driverPhoto ? (
                    <ViewerImage
                      image={{ name: 'driverPhoto', href_file: selectedDriverPhoto as string, data: {} }}
                      onClose={() => setSelectedDriverPhoto(null)}
                      buttons={[ViewerImageButton.CLOSE]}
                    />
                  ) : null}
                </div>
              ) : null}
            </Indent>
          </div>
        ))}
      </div>
      <div className="mt-2">
        <DictionaryPhoneForm
          value={phoneFormValue}
          onChange={setPhoneFormValue}
          loadingStatus={phoneFormEntry?.status}
          handleSubmit={handleAddPhone}
        />
      </div>
    </>
  )
}

export default PhonesFromDictionary
