import React from 'react'

import { Icon, IconType } from 'components/atoms/Icon'

import classes from './style.module.css'

const TelegramNumberLink: React.FC<{ phone: string }> = ({ phone }) =>
  phone ? (
    <a target="_blank" rel="noopener noreferrer" href={`https://t.me/+${phone}`} className={classes.link} style={{ height: '32px' }}>
      <Icon type={IconType.Telegram} width="32px" height="32px" />
    </a>
  ) : null

export default TelegramNumberLink
