export const truckRegex =
  'regex:/^(([a-zA-Z]{1}[0-9]{3}[a-zA-Z]{2}[0-9]{2,3})|([0-9]{3}[A-Z]{3}[0-9]{2})|([a-zA-Z]{1}[0-9]{4}[a-zA-Z]{2})|([0-9]{2}KG[0-9]{3,4}[A-Z]{1,3})|([0-9]{5,6}[A-Z]{1,3})|([0-9]{6}[A-Z]{2})|([A-Z]{2}[0-9]{4}[1-9]{1})|([A-Z][0-9]{3}[A-Z]{2,3})|([0-9]{2}[A-Z][0-9]{3}[A-Z]{2})|([A-Z]{2}[0-9]{4}[A-Z]{2})|([A-Z]{3}[0-9]{3,4})|([A-Z]{1}[0-9]{4}[A-Z]{1,2})|([0-9]{3,4}[A-Z]{2,3})|([0-9]{2,3}[A-Z]{1,2}[0-9]{2,4}))$/'

export const getTransportForServer = (numberTS: string) => `${numberTS}`

export const convertCyrillicToLatin = (text: string) => {
  const translationMap: { [key: string]: string } = {
    А: 'A',
    В: 'B',
    Е: 'E',
    К: 'K',
    М: 'M',
    Н: 'H',
    О: 'O',
    Р: 'P',
    С: 'C',
    Т: 'T',
    У: 'Y',
    Х: 'X',
  }

  return text
    .toUpperCase()
    .split('')
    .map((char: string) => translationMap[char] || char)
    .join('')
    .replace(/[-._/\\()\s"';:]/g, '')
}
