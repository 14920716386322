import React from 'react'

import { ReactComponent as EyeIcon } from 'assets/icons/Eye.svg'
import { ReactComponent as EditIcon } from 'assets/icons/Edit.svg'
import { ReactComponent as DownloadIcon } from 'assets/icons/Download.svg'
import { ReactComponent as UploadIcon } from 'assets/icons/Upload.svg'
import { ReactComponent as MenuIcon } from 'assets/icons/Menu.svg'
import { ReactComponent as AccountCircleIcon } from 'assets/icons/Account-circle.svg'
import { ReactComponent as ArrowIcon } from 'assets/icons/Arrow.svg'
import { ReactComponent as UserIcon } from 'assets/icons/User.svg'
import { ReactComponent as ArrowDownIcon } from 'assets/icons/Arrow-down.svg'
import { ReactComponent as BackArrowIcon } from 'assets/icons/Back-arrow.svg'
import { ReactComponent as VideoIcon } from 'assets/icons/Video.svg'
import { ReactComponent as WarningIcon } from 'assets/icons/Warning.svg'
import { ReactComponent as UpdateIcon } from 'assets/icons/Update.svg'
import { ReactComponent as TrashIcon } from 'assets/icons/Trash.svg'
import { ReactComponent as UnlockIcon } from 'assets/icons/Unlock.svg'
import { ReactComponent as StarIcon } from 'assets/icons/Star.svg'
import { ReactComponent as WebIcon } from 'assets/icons/Web.svg'
import { ReactComponent as TimeIcon } from 'assets/icons/Time.svg'
import { ReactComponent as SettingsIcon } from 'assets/icons/Settings.svg'
import { ReactComponent as QRIcon } from 'assets/icons/QR.svg'
import { ReactComponent as BellIcon } from 'assets/icons/Bell.svg'
import { ReactComponent as ShareIcon } from 'assets/icons/Share.svg'
import { ReactComponent as SearchIcon } from 'assets/icons/Search.svg'
import { ReactComponent as PrintIcon } from 'assets/icons/Print.svg'
import { ReactComponent as PlayIcon } from 'assets/icons/Play.svg'
import { ReactComponent as PlusIcon } from 'assets/icons/Plus.svg'
import { ReactComponent as RubleIcon } from 'assets/icons/Ruble.svg'
import { ReactComponent as MailIcon } from 'assets/icons/Mail.svg'
import { ReactComponent as BoxIcon } from 'assets/icons/Box.svg'
import { ReactComponent as CalendarIcon } from 'assets/icons/Calendar.svg'
import { ReactComponent as PlaceIcon } from 'assets/icons/Place.svg'
import { ReactComponent as LikeIcon } from 'assets/icons/Like.svg'
import { ReactComponent as HomeIcon } from 'assets/icons/Home.svg'
import { ReactComponent as MicrophoneIcon } from 'assets/icons/Microphone.svg'
import { ReactComponent as HintIcon } from 'assets/icons/Hint.svg'
import { ReactComponent as Hint2Icon } from 'assets/icons/Hint-2.svg'
import { ReactComponent as FingerprintIcon } from 'assets/icons/Fingerprint.svg'
import { ReactComponent as MapPinIcon } from 'assets/icons/Map-pin.svg'
import { ReactComponent as MenuDottedIcon } from 'assets/icons/Menu-dotted.svg'
import { ReactComponent as GraphIcon } from 'assets/icons/Graph.svg'
import { ReactComponent as LoginIcon } from 'assets/icons/Login.svg'
import { ReactComponent as FavoritesIcon } from 'assets/icons/Favorites.svg'
import { ReactComponent as PhoneIcon } from 'assets/icons/Phone.svg'
import { ReactComponent as PresentIcon } from 'assets/icons/Present.svg'
import { ReactComponent as FilterIcon } from 'assets/icons/Filter.svg'
import { ReactComponent as KeyIcon } from 'assets/icons/Key.svg'
import { ReactComponent as ImageIcon } from 'assets/icons/Image.svg'
import { ReactComponent as DollarIcon } from 'assets/icons/Dollar.svg'
import { ReactComponent as CopyIcon } from 'assets/icons/Copy.svg'
import { ReactComponent as Copy2Icon } from 'assets/icons/Copy-2.svg'
import { ReactComponent as CommentIcon } from 'assets/icons/Comment.svg'
import { ReactComponent as Comment2Icon } from 'assets/icons/Comment-2.svg'
import { ReactComponent as CloudIcon } from 'assets/icons/Cloud.svg'
import { ReactComponent as CheckIcon } from 'assets/icons/Check.svg'
import { ReactComponent as CheckCircleIcon } from 'assets/icons/Check-circle.svg'
import { ReactComponent as ChangeIcon } from 'assets/icons/Change.svg'
import { ReactComponent as Change2Icon } from 'assets/icons/Change-2.svg'
import { ReactComponent as CartClassicIcon } from 'assets/icons/Cart-classic.svg'
import { ReactComponent as CartFashionIcon } from 'assets/icons/Cart-fashion.svg'
import { ReactComponent as BusinessCenterIcon } from 'assets/icons/Business-center.svg'
import { ReactComponent as BookmarkIcon } from 'assets/icons/Bookmark.svg'
import { ReactComponent as ExternalLinkIcon } from 'assets/icons/External-link.svg'
import { ReactComponent as CloseIcon } from 'assets/icons/Close.svg'
import { ReactComponent as CloseCircleIcon } from 'assets/icons/Close-circle.svg'
import { ReactComponent as DocumentIcon } from 'assets/icons/Document.svg'
import { ReactComponent as Document2Icon } from 'assets/icons/Document-2.svg'
import { ReactComponent as Document3Icon } from 'assets/icons/Document-3.svg'
import { ReactComponent as LockIcon } from 'assets/icons/Lock.svg'
import { ReactComponent as SendIcon } from 'assets/icons/Send.svg'
import { ReactComponent as PercentIcon } from 'assets/icons/Percent.svg'
import { ReactComponent as AccountCalendarIcon } from 'assets/icons/Account-calendar.svg'
import { ReactComponent as BusinessCenter2Icon } from 'assets/icons/Business-center-2.svg'
import { ReactComponent as CameraIcon } from 'assets/icons/Camera.svg'
import { ReactComponent as FlagIcon } from 'assets/icons/Flag.svg'
import { ReactComponent as TruckIcon } from 'assets/icons/Truck.svg'
import { ReactComponent as DriverIcon } from 'assets/icons/Driver.svg'
import { ReactComponent as ZoomInIcon } from 'assets/icons/Zoom-in.svg'
import { ReactComponent as ZoomOutIcon } from 'assets/icons/Zoom-out.svg'
import { ReactComponent as OfferIcon } from 'assets/icons/Offer.svg'
import { ReactComponent as WhatsappIcon } from 'assets/icons/Whatsapp.svg'
import { ReactComponent as SpeakingIcon } from 'assets/icons/Speaking.svg'
import { ReactComponent as ViberIcon } from 'assets/icons/Viber.svg'
import { ReactComponent as BellIcon2 } from 'assets/icons/Bell2.svg'
import { ReactComponent as TelegramIcon } from 'assets/icons/Telegram.svg'

export enum IconType {
  Eye,
  Edit,
  Download,
  Upload,
  Menu,
  AccountCircle,
  Arrow,
  User,
  ArrowDown,
  BackArrow,
  Video,
  Warning,
  Update,
  Trash,
  Unlock,
  Star,
  Web,
  Time,
  Settings,
  QR,
  Bell,
  Bell2,
  Share,
  Search,
  Print,
  Play,
  Plus,
  Ruble,
  Mail,
  Box,
  Calendar,
  Place,
  Like,
  Home,
  Microphone,
  Hint,
  Hint2,
  Fingerprint,
  MapPin,
  MenuDotted,
  Graph,
  Login,
  Favorites,
  Phone,
  Present,
  Filter,
  Key,
  Image,
  Dollar,
  Copy,
  Copy2,
  Comment,
  Comment2,
  Cloud,
  Check,
  CheckCircle,
  Change,
  Change2,
  CartClassic,
  CartFashion,
  BusinessCenter,
  Bookmark,
  ExternalLink,
  Close,
  CloseCircle,
  Document,
  Document2,
  Document3,
  Lock,
  Send,
  Percent,
  AccountCalendar,
  BusinessCenter2,
  Camera,
  Flag,
  Truck,
  Driver,
  ZoomIn,
  ZoomOut,
  Offer,
  Whatsapp,
  Speaking,
  Viber,
  Telegram,
}

const Icons = {
  [IconType.Eye]: EyeIcon,
  [IconType.Edit]: EditIcon,
  [IconType.Download]: DownloadIcon,
  [IconType.Upload]: UploadIcon,
  [IconType.Menu]: MenuIcon,
  [IconType.AccountCircle]: AccountCircleIcon,
  [IconType.Arrow]: ArrowIcon,
  [IconType.User]: UserIcon,
  [IconType.ArrowDown]: ArrowDownIcon,
  [IconType.BackArrow]: BackArrowIcon,
  [IconType.Video]: VideoIcon,
  [IconType.Warning]: WarningIcon,
  [IconType.Update]: UpdateIcon,
  [IconType.Trash]: TrashIcon,
  [IconType.Unlock]: UnlockIcon,
  [IconType.Star]: StarIcon,
  [IconType.Web]: WebIcon,
  [IconType.Time]: TimeIcon,
  [IconType.Settings]: SettingsIcon,
  [IconType.QR]: QRIcon,
  [IconType.Bell]: BellIcon,
  [IconType.Bell2]: BellIcon2,
  [IconType.Share]: ShareIcon,
  [IconType.Search]: SearchIcon,
  [IconType.Print]: PrintIcon,
  [IconType.Play]: PlayIcon,
  [IconType.Plus]: PlusIcon,
  [IconType.Ruble]: RubleIcon,
  [IconType.Mail]: MailIcon,
  [IconType.Box]: BoxIcon,
  [IconType.Calendar]: CalendarIcon,
  [IconType.Place]: PlaceIcon,
  [IconType.Like]: LikeIcon,
  [IconType.Home]: HomeIcon,
  [IconType.Microphone]: MicrophoneIcon,
  [IconType.Hint]: HintIcon,
  [IconType.Hint2]: Hint2Icon,
  [IconType.Fingerprint]: FingerprintIcon,
  [IconType.MapPin]: MapPinIcon,
  [IconType.MenuDotted]: MenuDottedIcon,
  [IconType.Graph]: GraphIcon,
  [IconType.Login]: LoginIcon,
  [IconType.Favorites]: FavoritesIcon,
  [IconType.Phone]: PhoneIcon,
  [IconType.Present]: PresentIcon,
  [IconType.Filter]: FilterIcon,
  [IconType.Key]: KeyIcon,
  [IconType.Image]: ImageIcon,
  [IconType.Dollar]: DollarIcon,
  [IconType.Copy]: CopyIcon,
  [IconType.Copy2]: Copy2Icon,
  [IconType.Comment]: CommentIcon,
  [IconType.Comment2]: Comment2Icon,
  [IconType.Cloud]: CloudIcon,
  [IconType.Check]: CheckIcon,
  [IconType.CheckCircle]: CheckCircleIcon,
  [IconType.Change]: ChangeIcon,
  [IconType.Change2]: Change2Icon,
  [IconType.CartClassic]: CartClassicIcon,
  [IconType.CartFashion]: CartFashionIcon,
  [IconType.BusinessCenter]: BusinessCenterIcon,
  [IconType.Bookmark]: BookmarkIcon,
  [IconType.ExternalLink]: ExternalLinkIcon,
  [IconType.Close]: CloseIcon,
  [IconType.CloseCircle]: CloseCircleIcon,
  [IconType.Document]: DocumentIcon,
  [IconType.Document2]: Document2Icon,
  [IconType.Document3]: Document3Icon,
  [IconType.Lock]: LockIcon,
  [IconType.Send]: SendIcon,
  [IconType.Percent]: PercentIcon,
  [IconType.AccountCalendar]: AccountCalendarIcon,
  [IconType.BusinessCenter2]: BusinessCenter2Icon,
  [IconType.Camera]: CameraIcon,
  [IconType.Flag]: FlagIcon,
  [IconType.Truck]: TruckIcon,
  [IconType.Driver]: DriverIcon,
  [IconType.ZoomIn]: ZoomInIcon,
  [IconType.ZoomOut]: ZoomOutIcon,
  [IconType.Offer]: OfferIcon,
  [IconType.Whatsapp]: WhatsappIcon,
  [IconType.Speaking]: SpeakingIcon,
  [IconType.Viber]: ViberIcon,
  [IconType.Telegram]: TelegramIcon,
}

interface IconProps {
  type: IconType
  width?: string
  height?: string
  className?: string
}

export const Icon: React.FC<IconProps> = ({ type, ...props }) => React.createElement(Icons[type], { ...props })
