import React, { useCallback, useEffect, useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'

import { LoadingStatus } from 'types/loading'
import { OrderFields, PriorityId, ResolutionId } from 'types/order'
import { UserRole } from 'types/user'

import { ACTIVE_ORDER_STATUSES } from 'constants/order'

import { selectLoadingTakeOrderStatus, selectOrderFilter, selectOrders, selectSearchOrders } from 'store/orders/orders.selectors'
import { selectSelectLists } from 'store/selectLists/selectLists.selectors'
import { selectNotifications } from 'store/notifications/notifications.selectors'
import { selectCurrentUser } from 'store/users/users.selectors'

import { fetchTakeOrderRequest } from 'store/orders/orders.actions'

import getStringFromSelectList from 'utils/getStringFromSelectList'
import isOrderInNotifications from 'utils/isOrderInNotifications'

import { useOrderTaking } from 'hooks/use-order-taking'

import { Icon, IconType } from 'components/atoms/Icon'
import TextWithLineBreaks from 'components/atoms/TextWithLineBreaks'
import LoadingProgressIndeterminate from 'components/atoms/LoadingProgressIndeterminate'
import OrderTimer from 'components/atoms/OrderTimer'
import Button from 'components/molecules/Button'

import { isFreeOrder } from './utils'

import classes from './style.module.css'
import SearchMatch from './SearchMatch'
import { useSortedOrders } from './orders/hook/use-sorted-orders'

export const OrderListManager = () => {
  const dispatch = useDispatch()
  const orderFilter = useSelector(selectOrderFilter)
  const notifications = useSelector(selectNotifications)
  const currentUser = useSelector(selectCurrentUser)!
  const loadingTakeOrderStatus = useSelector(selectLoadingTakeOrderStatus)
  const isSearchOrders = !!useSelector(selectSearchOrders)
  const orders = useSelector(selectOrders)

  const selectLists = useSelector(selectSelectLists)

  const { canSelectOrderToTake } = useOrderTaking()

  const canTakeOrder = useCallback((order) => isFreeOrder(order, currentUser) && canSelectOrderToTake, [canSelectOrderToTake, currentUser])

  const [currentOrder, setCurrentOrder] = useState('')
  const [startingPosition, setStartingPosition] = useState<null | { x: number; y: number }>(null)

  const takeOrder = useCallback(
    (orderNumber) => {
      setCurrentOrder(orderNumber)
      dispatch(fetchTakeOrderRequest(orderNumber))
    },
    [dispatch],
  )

  const onTouchStart = useCallback((event, orderNumber) => {
    setCurrentOrder(orderNumber)
    setStartingPosition({
      x: event.touches[0].screenX,
      y: event.touches[0].screenY,
    })
  }, [])

  const onTouchMove = useCallback(
    (event) => {
      if (startingPosition && event.touches[0].screenX - startingPosition!.x > 0) {
        if (Number(event.currentTarget.style.left.substr(0, event.currentTarget.style.left.length - 2)) > event.currentTarget.offsetWidth / 2)
          event.currentTarget.setAttribute('style', `background-color:#7dc17b; left:${event.touches[0].screenX - startingPosition!.x}px`)
        else {
          event.currentTarget.setAttribute('style', `left:${event.touches[0].screenX - startingPosition!.x}px`)
        }
      } else {
        event.currentTarget.setAttribute('style', `left:0px`)
      }
    },
    [startingPosition],
  )

  const onTouchEnd = useCallback(
    (event) => {
      if (Number(event.currentTarget.style.left.substr(0, event.currentTarget.style.left.length - 2)) > event.currentTarget.offsetWidth / 2)
        dispatch(fetchTakeOrderRequest(currentOrder))
      setStartingPosition(null)
      event.currentTarget.setAttribute('style', `left:0px`)
    },
    [currentOrder, dispatch],
  )

  useEffect(() => {
    if (loadingTakeOrderStatus === LoadingStatus.FAILED) setCurrentOrder('')
  }, [loadingTakeOrderStatus])

  const sortedOrders = useSortedOrders(currentUser.role)

  const styleOrder = useCallback(
    (order) => {
      if (
        Number(order.executorExpert) === Number(currentUser.id) &&
        Number(order.resolution) === Number(ResolutionId.E_PROCESSING) &&
        order.orderComment?.includes('Данные уточнены')
      ) {
        return 'list-group-item-warning'
      }

      const isManagerProcessingL1 = Number(order.resolution) === Number(ResolutionId.L1_PROCESSING) && currentUser!.role === UserRole.MANAGER
      if (isManagerProcessingL1) return 'list-group-item-success'

      return canTakeOrder(order) && !isSearchOrders ? 'list-group-item-success' : ''
    },
    [canTakeOrder, currentUser, isSearchOrders],
  )

  if (loadingTakeOrderStatus === LoadingStatus.SUCCESS) {
    const path = currentUser!.role === UserRole.MANAGER ? `/order/${currentOrder}/edit` : `/order/${currentOrder}`
    return <Redirect to={path} />
  }

  return (
    <>
      {(isSearchOrders ? orders : sortedOrders).map((order) => {
        if (orderFilter(order, currentUser!, selectLists)) {
          return (
            <Link
              to={`/order/${order.orderNumber}`}
              key={order.orderNumber}
              className={classNames(
                'list-group-item list-group-item-action flex-column align-items-start',
                classes.order,
                styleOrder(order),
                loadingTakeOrderStatus === LoadingStatus.PENDING ? classes.disabledLink : '',
              )}
              onTouchStart={(event) => {
                if (canTakeOrder(order) && !isSearchOrders) onTouchStart(event, order.orderNumber)
              }}
              onTouchMove={(event) => {
                if (canTakeOrder(order) && !isSearchOrders) onTouchMove(event)
              }}
              onTouchEnd={(event) => {
                if (canTakeOrder(order) && !isSearchOrders) onTouchEnd(event)
              }}
              style={{
                textDecoration: 'none',
                transition: !currentOrder || !startingPosition ? 'left 0.3s' : 'none',
                border: '2px solid rgba(0,0,0,.125)',
              }}
              onClick={() => {
                // todo refactor
                if (
                  !canTakeOrder(order) ||
                  isSearchOrders ||
                  currentUser.role !== UserRole.EXPERT ||
                  String(order.resolution) !== ResolutionId.FOR_E ||
                  order[OrderFields.EXECUTOR_EXPERT] !== currentUser!.id
                )
                  return
                takeOrder(order.orderNumber)
              }}
            >
              {isSearchOrders ? <SearchMatch order={order} /> : null}
              <div className={classNames('d-flex w-100 justify-content-between', classes.icons)}>
                <span>
                  <span className="text-primary">{`${order.orderTime} `}</span>
                  <span className="text-primary">{getStringFromSelectList(order.clientName, selectLists?.clientName)}</span>
                </span>
                <div>
                  {!isSearchOrders && ACTIVE_ORDER_STATUSES.includes(String(order[OrderFields.RESOLUTION]) as ResolutionId) ? (
                    <div className="d-inline-block">
                      <OrderTimer orderDate={order.orderDate} orderType={order.orderType} />
                    </div>
                  ) : null}
                  {notifications && notifications.length && isOrderInNotifications(order.orderNumber, notifications) ? (
                    <div title="Новое сообщение" className="d-inline-block">
                      <Icon className={classes.notificationIcon} type={IconType.Comment2} />
                    </div>
                  ) : // <span className={"badge badge-danger text-danger " + classes.header_newMessage} title="Новое сообщение">0</span>
                  null}
                </div>
              </div>
              <div className="mb-1 mt-1">
                <p className="p-0 m-0">{getStringFromSelectList(order.orderType, selectLists?.orderType)}</p>{' '}
                <p className="font-weight-bold p-0 m-0">{order.logisticName}</p>
                <p className="font-weight-bold p-0 m-0">{order.driverFio}</p>
                <hr />
                {order.resolution ? (
                  <div className={classNames('col-lg-3 col-md-6 col-sm-12 text-center', classes.resolution, classes.resolution_gray)}>
                    {getStringFromSelectList(order.resolution, selectLists?.resolution)}
                  </div>
                ) : null}
                L1:&nbsp;
                <span
                  className={classNames(
                    'font-weight-bold',
                    String(order.resolution) === ResolutionId.CLARIFY_DATA ||
                      String(order.resolution) === ResolutionId.L1_PROCESSING ||
                      String(order.resolution) === ResolutionId.DRIVER_IS_NOT_RESPONDING
                      ? classes.underline_text
                      : '',
                  )}
                >
                  {order.executorL1 ? getStringFromSelectList(order.executorL1, selectLists?.executor) : <>нет</>}
                </span>
                &nbsp; E:&nbsp;
                <span
                  className={classNames(
                    'font-weight-bold',
                    String(order.resolution) === ResolutionId.FOR_E || String(order.resolution) === ResolutionId.E_PROCESSING
                      ? classes.underline_text
                      : '',
                  )}
                >
                  {order.executorExpert ? getStringFromSelectList(order.executorExpert, selectLists?.executor) : <>нет</>}
                </span>
                <p className="font-weight-normal p-0 m-0">
                  <TextWithLineBreaks
                    text={order.orderComment && order?.orderComment?.length > 250 ? `${order?.orderComment?.substr(0, 250)}...` : order.orderComment}
                  />
                </p>
              </div>
              <div className="d-flex" style={{ gap: '4px', flexWrap: 'wrap' }}>
                <span className="badge badge-info">{order.orderNumber}</span>
                {order[OrderFields.HAS_BELARUSIANS] ? <span className="badge badge-danger">Белорусы</span> : null}
                {order[OrderFields.DRIVER_CREDIT_RATING] ? <span className="badge badge-danger">Кредитный рейтинг</span> : null}
                {String(order.priority) !== PriorityId.NONE ? (
                  <span className="badge badge-danger">{getStringFromSelectList(order.priority, selectLists?.priority)}</span>
                ) : null}
              </div>
              {canTakeOrder(order) && !isSearchOrders ? (
                <Button
                  className={classes.takeOrder}
                  variant="primary"
                  onClick={(event) => {
                    event.preventDefault()
                    event.stopPropagation()
                    takeOrder(order.orderNumber)
                  }}
                  isLoading={currentOrder === order.orderNumber && loadingTakeOrderStatus === LoadingStatus.PENDING}
                  content="Взять заявку"
                />
              ) : null}
              {currentOrder === order.orderNumber && loadingTakeOrderStatus === LoadingStatus.PENDING ? (
                <div className={classes.LoadingProgressIndeterminate}>
                  <LoadingProgressIndeterminate />
                </div>
              ) : null}
            </Link>
          )
        }
        return null
      })}
    </>
  )
}

export default OrderListManager
