import { EditOrderData, GetLogisticInfo, GetOrderData, OrderFields, OrderFormData } from 'types/order'
import { api, transformResponse, v3Api } from './axios'

// @TODO implement REST
// @TODO use GET instead of POST
export const fetchOrder = (data: GetOrderData, token: string) =>
  transformResponse(
    api.post('/getOrder', data, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }),
  )

export const fetchOrderList = (token: string) => {
  return transformResponse(
    api.get('/order', {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }),
  )
}

export const searchOrders = (token: string, search: string) => {
  return transformResponse(
    v3Api.get(`/order/search?search=${search}&count=10`, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }),
  )
}

export const fetchCreateOrder = (data: OrderFormData, token: string) =>
  transformResponse(
    api.post('/createOrder', data, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }),
  )

export const fetchEditOrder = (data: EditOrderData, token: string) =>
  transformResponse(
    api.post('/editOrder', data, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }),
  )

export const CloseOrder = (data: EditOrderData, token: string) =>
  transformResponse(
    v3Api.post(`order/${data[OrderFields.ORDER_NUMBER]}/close`, data.fields, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }),
  )

export const fetchLogisticInfo = (data: GetLogisticInfo, token: string) =>
  transformResponse(
    api.post('/getOrgan', data, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }),
  )

export const fetchTakeOrder = (orderNumber: string, token: string) =>
  transformResponse(
    api.get(`/takeOrder/${orderNumber}`, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }),
  )

export const fetchTransferOrder = (data: EditOrderData, token: string) =>
  transformResponse(
    api.post(`/transferOrder/${data[OrderFields.ORDER_NUMBER]}`, data.fields, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }),
  )

export const fetchClarifyData = (data: EditOrderData, token: string) =>
  transformResponse(
    v3Api.post(
      `order/${data[OrderFields.ORDER_NUMBER]}/clarify-data`,
      {
        comment: data.fields?.comment,
      },
      {
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      },
    ),
  )

export const getOrderTime = (orderNumber: string, token: string) =>
  transformResponse(
    v3Api.get(`order/time/${orderNumber}`, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }),
  )

export const createReport = (data: { year: string; month: string }, token: string) =>
  transformResponse(
    v3Api.get(`order/report?year=${data.year}&month=${data.month}`, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }),
  )
